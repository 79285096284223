export const region = process.env.NODE_ENV === "production" ? "australia-southeast1" : "australia-southeast1";
export const azureFunctionService = "api-sightforms-prod";
export const cloudFunctionsBaseURL =
  process.env.NODE_ENV === "production" ? `https://${azureFunctionService}.azurewebsites.net/api` : `http://localhost:7071/api`;
export const firebaseFunctionsBaseURL =
  process.env.NODE_ENV === "production"
    ? `https://${region}-sight-forms-prod.cloudfunctions.net`
    : `http://127.0.0.1:5001/sight-forms/${region}`;
export const outputEndpointBaseURL =
  process.env.NODE_ENV === "production" ? "https://widget-sight-forms-prod.web.app" : "http://localhost:8081";
export const panelEndpointBaseURL =
  process.env.NODE_ENV === "production" ? "https://form-sight-forms-prod.web.app" : "http://localhost:8080";
export const emptyImage1x1 =
  "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==";
export const sendGridApiKey =
  process.env.NODE_ENV === "production"
    ? "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"
    : "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";
export const baseMediaUrl =
  process.env.NODE_ENV === "production"
    ? "https://firebasestorage.googleapis.com/v0/b/sight-forms-prod.appspot.com/o/"
    : "https://firebasestorage.googleapis.com/v0/b/sight-forms.appspot.com/o/";
export const AppVersion = 4.1;
export const ipGeolocationKey =
  process.env.NODE_ENV === "production" ? "324d6a4d5e224ff1ada86b493a473dae" : "324d6a4d5e224ff1ada86b493a473dae";
